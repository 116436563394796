import { template as template_229e8c29e522442dafd9913c2d2938e1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_229e8c29e522442dafd9913c2d2938e1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
