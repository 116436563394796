import { template as template_1a4f5634d72a4890b4b8d86f82e2b81c } from "@ember/template-compiler";
const WelcomeHeader = template_1a4f5634d72a4890b4b8d86f82e2b81c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
