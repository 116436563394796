import { template as template_37133877c88143419c668ae1a18b537f } from "@ember/template-compiler";
const FKControlMenuContainer = template_37133877c88143419c668ae1a18b537f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
