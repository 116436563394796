import { template as template_f3e713cef0064066b147958a8d5a5d59 } from "@ember/template-compiler";
const FKLabel = template_f3e713cef0064066b147958a8d5a5d59(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
